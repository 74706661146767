// gallery_slider_controller.js
import { Controller } from 'stimulus';
import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

export default class extends Controller {
  connect() {
    const eventCards = this.element.querySelectorAll('.event_card');
    const lastEventCard = eventCards[eventCards.length - 1];
    if (lastEventCard) {
      lastEventCard.addEventListener('eventCardProcessed', this.handleLastEventCardProcessed);
    }
  }

  disconnect() {
    const eventCards = this.element.querySelectorAll('.event_card');
    const lastEventCard = eventCards[eventCards.length - 1];
    if (lastEventCard) {
      lastEventCard.removeEventListener('eventCardProcessed', this.handleLastEventCardProcessed);
    }
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }

  handleLastEventCardProcessed = () => {
    // limit the slider to 8 slides
    this.element.querySelectorAll('.event_card').forEach((el, index) => {
      if (index >= 8) el.remove();
    });
    this.initGallerySlider();
  };

  initGallerySlider() {
    new Swiper('.js-eventsSlider', {
      slidesPerView: 1.125,
      spaceBetween: 35,
      navigation: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      breakpoints: {
        700: {
          slidesPerView: 2.125,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      },
    });
  }
}
